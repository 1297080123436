import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import {myStyles} from '../Utils/customTemplate.js'
import AppBarAiTLAS from './AppBarAiTLAS.jsx';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
const useStyles = (theme => (myStyles(theme)));

class About extends React.Component{
    render(){
        const {classes} = this.props;
        return (

            <div>
                <AppBarAiTLAS></AppBarAiTLAS>
                <br/><br/><br/><br/>
                <Grid container spacing={3} justifyContent="center" alignItems="center">
                    <Grid item xs={12} md ={10} lg = {8}>
                        <br/><br/>
                        <Paper className={classes.aboutPaper}>
                            <h2>About AiTLAS Semantic Data Catalog</h2>
                            <Typography style={{textAlign: 'justify'}}>
                            <span className={classes.yellowSpan}>AiTLAS Semantic Data Catalog</span> is a data catalogue for semantic management of Earth Observation (EO) datasets. 
                            Being tightly integrated with the <a href="http://aitlas.bvlabs.ai" style={{textDecoration: 'none'}}><span className={classes.yellowSpan}>AiTLAS toolbox</span></a>, it provides uniform and standardized access to AI-ready EO datasets that can be easily integrated and exploited into data analysis pipelines. 
                            AiTLAS is an open-source Python-based toolbox that has the key objective to facilitate the uptake of EO data by AI experts and vice versa - the uptake of (advanced) AI methods by EO experts. 
                            Ensuring proper, correct, robust, trustworthy access to EO data and AI benchmark study data is of utmost importance for the further uptake of AI in EO tasks and analysis pipelines.
                            <br></br> <br></br>

                            This cataloge ensures data standardization and enrichment of EO data with external non-EO data and metadata annotations. To standardize EO data, this catalogue utilizes formal knowledge representation techniques (i.e., semantic annotation of EO data with terms defined in ontologies and formal vocabularies) and adheres to standards for data management such as the <a href="https://www.go-fair.org/fair-principles/" style={{textDecoration: 'none'}}><span className={classes.yellowSpan}>FAIR </span></a>(Findable, Accessible, Interoperable, Reusable) and <a href="https://www.nature.com/articles/s41597-020-0486-7" style={{textDecoration: 'none'}}><span className={classes.yellowSpan}>TRUST</span></a> (Transparency, Responsibility, User focus, Sustainability and Technology) data principles. 

                            <br></br> <br></br>

                            The unique combination of coupling the EO datasets available in the AiTLAS toolbox with ontology-based semantic descriptions makes the catalogue a reliable go-to resource for practitioners from both the ML and EO domains. The datasets can be accessed in a user-friendly manner. End-users can easily gain insights into the most relevant characteristics of the datasets, and download and integrate them in their analysis pipelines. 
                            </Typography>
                         </Paper>
                    </Grid>
                </Grid>     
            </div>
        )
    }
}
export default withStyles(useStyles)(About)