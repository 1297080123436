import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import {myStyles} from '../Utils/customTemplate.js'
import DatasetGrid from './DatasetGrid';
import AppBarAiTLAS from './AppBarAiTLAS';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Refresh from '@material-ui/icons/Refresh';
import TextField from '@material-ui/core/TextField';

const http = require('http')

const useStyles = (theme => (myStyles(theme)));

class ClippedDrawer extends React.Component {
  constructor(props){
    super(props)
    this.handleChangeNumImages = this.handleChangeNumImages.bind(this)
    this.state = {
      filteredDatasets: "N/A",
      num_images_value: [],
      num_labels_value: [],
      MLTasks: {
        'binary classification': true,
        'multi-class classification': true, 
        'multi label classification': true
      },
      image_size_value: "",
      EOTasks: {

      }
    }
  }
  componentDidMount(){
    this.getNumImagesInfo()
    this.getNumLabelsInfo()
    this.getEOTasksInfo()
  }

  handleChangeNumImages = (event, newValue) => {
    this.setState({
      num_images_value: newValue
    })
  };

  handleChangeNumLabels = (event, newValue) => {
    this.setState({
      num_labels_value: newValue
    })
  };

  getNumLabelsInfo(){
    try{
      var query = `
      PREFIX aitlas: <http://www.AiTLASOntology.com/>
      PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
      SELECT DISTINCT  ?dataset_resource (count(distinct ?labelValue) as ?count)
      WHERE{
        ?dataset_resource aitlas:has_labelset ?labelset.
        ?labelset <http://purl.obolibrary.org/obo/BFO_0000051> ?label. 
        ?label rdfs:label ?labelValue
      }
      group by ?dataset_resource
      order by ?count
      `
      var fuseki_req = "http://212.44.107.112:3030/eodata/query?query="+encodeURIComponent(query)
      http.get(fuseki_req, (resp) => {
          let data = '';
      
          // A chunk of data has been received.
          resp.on('data', (chunk) => {
              data += chunk;
          });
      
          // The whole response has been received. Print out the result.
          resp.on('end', () => {
              var json = JSON.parse(data)
              if(json.results && json.results.bindings){
                  let minNum = Number(json.results.bindings[0].count.value)
                  let maxNum = Number(json.results.bindings[json.results.bindings.length-1].count.value)
                  
                  this.setState({
                      minLabels: minNum,
                      maxLabels: maxNum,
                      num_labels_value: [minNum, maxNum]
                  })
              }
              else{
                  return console.log("No results. ")
              }
          });
      })
      .on("error", (err) => {
          console.log(err)
      });
    }
    catch(err){
        console.log(err)
    }
  }

  getNumImagesInfo(){
    try{
      var query = `
      PREFIX aitlas: <http://www.AiTLASOntology.com/>
      SELECT DISTINCT  ?dataset_resource ?numImages
      WHERE{
      ?dataset_resource aitlas:number_of_images ?numImages.
      }
      order by ?numImages
      `
      var fuseki_req = "http://212.44.107.112:3030/eodata/query?query="+encodeURIComponent(query)
      http.get(fuseki_req, (resp) => {
          let data = '';
      
          // A chunk of data has been received.
          resp.on('data', (chunk) => {
              data += chunk;
          });
      
          // The whole response has been received. Print out the result.
          resp.on('end', () => {
              var json = JSON.parse(data)
              if(json.results && json.results.bindings){
                  let minNum = Number(json.results.bindings[0].numImages.value)
                  let maxNum = Number(json.results.bindings[json.results.bindings.length-1].numImages.value)
                  
                  this.setState({
                      minImages: minNum,
                      maxImages: maxNum,
                      num_images_value: [minNum, maxNum]
                  })
              }
              else{
                  return console.log("No results. ")
              }
          });
      })
      .on("error", (err) => {
          console.log(err)
      });
    }
    catch(err){
        console.log(err)
    }
  }

  rangeFilter(item, feature){
    return feature+">="+item[0]+"&&"+feature+"<="+item[1];
  }

  filterMLTask = (MLTasksList, variable)=>{
    var str = "("
    for(var i = 0; i<MLTasksList.length; i++){
        str+="STRENDS("+variable+", '"+MLTasksList[i]+"')"
        if(i<MLTasksList.length-1)
        str+="||"
    }
    str += ")"
    return str
  }

  filterEOTask = ()=>{
    const EOtasksArr = []
    for(var key of Object.keys(this.state.EOTasks)){
      if(this.state.EOTasks[key])
      EOtasksArr.push(key)
    }

    var str = "("
    for(var i = 0; i<EOtasksArr.length; i++){
        str+="'"+EOtasksArr[i]+"'"
        if(i<EOtasksArr.length-1)
        str+=","
    }
    str += ")"
    return str

  }

  createImageSizeFilterStatement=()=>{
    var query_part = ""
    
    if(/^([<>]=?.*)$/.test(this.state.image_size_value)){
      //<=20x20
      
      let sign = this.state.image_size_value.split(/\d/)[0]
      let numbers = this.state.image_size_value.split(sign)[1]
      let i_x = numbers.split("x")[0]
      let i_y = numbers.split("x")[1]
      query_part = `FILTER(?image_size_x `+sign+i_x+ ` && ?image_size_y `+sign+i_y+`) .`

    }
    else if(this.state.image_size_value.includes('-')){
      //20x20-30x30
      let image_size_value_parts = this.state.image_size_value.split('-')
      let i_x_1 = image_size_value_parts[0].split("x")[0]
      let i_y_1 = image_size_value_parts[0].split("x")[1]
      let i_x_2 = image_size_value_parts[1].split("x")[0]
      let i_y_2 = image_size_value_parts[1].split("x")[1]
      query_part = `FILTER(?image_size_x >=`+i_x_1+ ` && ?image_size_x <=`+i_x_2+` && ?image_size_y >= `+i_y_1+` && ?image_size_y <= `+i_y_2+`) .`
    }
    else if(/^\d+x\d+$/.test(this.state.image_size_value)){
      // 20x20
      let image_size_value_parts = this.state.image_size_value.split("x")
      let i_x = image_size_value_parts[0]
      let i_y = image_size_value_parts[1]
      query_part = `FILTER(?image_size_x = `+i_x+ ` && ?image_size_y =`+i_y+`) .`
    }
     
    return query_part
  }

  getFilteredDatasets(){
    try{
      const MLtasksArr = []
      for(var key of Object.keys(this.state.MLTasks)){
        if(this.state.MLTasks[key])
          MLtasksArr.push(key)
      }
    
      var query = `
      PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
      PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
      PREFIX dc: <http://purl.org/dc/elements/1.1/>
      PREFIX aitlas: <http://www.AiTLASOntology.com/>
      SELECT DISTINCT ?dataset_id
      WHERE{
        ?dataset_resource rdf:type <http://www.AiTLASOntology.com/EO_image_dataset>.
        ?dataset_resource dc:identifier ?dataset_id .
        ?EOtask <http://purl.obolibrary.org/obo/IAO_0000136> ?dataset_resource .
        ?EOtask rdf:type <http://www.AiTLASOntology.com/EO_task> .
        ?EOtask rdfs:label ?EOtaskLabel . 
        FILTER((?EOtaskLabel) in `+this.filterEOTask()+`).
        ?dataset_resource aitlas:number_of_images ?numImages.`
      if(this.state.image_size_value!==""){
        query += `
        ?dataset_resource aitlas:image_size_x ?image_size_x .
  		  ?dataset_resource aitlas:image_size_y ?image_size_y .
        ` + this.createImageSizeFilterStatement()
      }
        query += `
        FILTER (`+this.rangeFilter(this.state.num_images_value, "?numImages")+`) .
        {
          select ?dataset_resource (count(?labelValue) as ?numLabels) 
          where {
            ?dataset_resource aitlas:has_labelset ?labelset.
            ?labelset <http://purl.obolibrary.org/obo/BFO_0000051> ?label. 
            ?label rdfs:label ?labelValue
          }
          group by ?dataset_resource
        }
        FILTER (`+this.rangeFilter(this.state.num_labels_value, "?numLabels")+`)
        ?MLTask <http://purl.obolibrary.org/obo/IAO_0000136> ?dataset_resource .
        ?MLTask rdf:type ?MLTaskclass. 
        ?MLTask rdfs:label ?MLTask_label .
        ?MLTaskclass rdfs:subClassOf <http://www.ontodm.com/OntoDM-core/OntoDM_000098>
        FILTER `+this.filterMLTask(MLtasksArr, "?MLTask_label")+`.
      }
      `

      var fuseki_req = "http://212.44.107.112:3030/eodata/query?query="+encodeURIComponent(query)
      http.get(fuseki_req, (resp) => {
          let data = '';
      
          // A chunk of data has been received.
          resp.on('data', (chunk) => {
              data += chunk;
          });
      
          // The whole response has been received. Print out the result.
          resp.on('end', () => {
              var json = JSON.parse(data)
              console.log("final")
              console.log(json)
              let arr = []
              if(json.results && json.results.bindings.length>0){
                json.results.bindings.forEach(el => {
                  let datasetID = String(el.dataset_id.value)
                  arr.push(datasetID)
                })
                
              }
              this.setState({filteredDatasets: arr})
              
          });
      })
      .on("error", (err) => {
          console.log(err)
      });
    }
    catch(err){
        console.log(err)
    }
  }

  getEOTasksInfo(){
    try{
      var query = `
      PREFIX aitlas: <http://www.AiTLASOntology.com/>
      PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
      PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
      SELECT DISTINCT ?EOtaskLabel
      WHERE{
        ?EOtask <http://purl.obolibrary.org/obo/IAO_0000136> ?dataset_resource .
        ?EOtask rdf:type aitlas:EO_task .
        ?EOtask rdfs:label ?EOtaskLabel
      }
      `
      var fuseki_req = "http://212.44.107.112:3030/eodata/query?query="+encodeURIComponent(query)
      http.get(fuseki_req, (resp) => {
          let data = '';
      
          // A chunk of data has been received.
          resp.on('data', (chunk) => {
              data += chunk;
          });
      
          // The whole response has been received. Print out the result.
          resp.on('end', () => {
              var json = JSON.parse(data)
              let newEOTasks = {}
              if(json.results && json.results.bindings){
                json.results.bindings.forEach(el => {
                  let eoTask = String(el.EOtaskLabel.value)
                  if(!newEOTasks.hasOwnProperty(eoTask)) 
                    newEOTasks[eoTask] = true
                })
                this.setState({EOTasks: newEOTasks})
              }
              else{
                  return console.log("No results. ")
              }
          });
      })
      .on("error", (err) => {
          console.log(err)
      });
    }
    catch(err){
        console.log(err)
    }
  }

  handleChangeMLTask = (event)=>{
    let newMLtasks = {}
    for (var key of Object.keys(this.state.MLTasks))
        newMLtasks[key] = event.target.name === key ? event.target.checked : this.state.MLTasks[key]
    this.setState({
      MLTasks: newMLtasks
    })
  }

  handleChangeEOTask = (event)=>{
    let newEOtasks = {}
    for (var key of Object.keys(this.state.EOTasks)){
      newEOtasks[key] = event.target.name === key ? event.target.checked : this.state.EOTasks[key]
    }
      
    this.setState({
      EOTasks: newEOtasks
    })
  }

  handleFilterClick = () => {
    this.getFilteredDatasets()

  }

  handleFilterReset = () => {
    this.getNumImagesInfo()
    this.getEOTasksInfo()
    this.getNumLabelsInfo()
    var newArr = {}
    for(var key of Object.keys(this.state.MLTasks)){
      newArr[key] = true
    }
    this.setState({
      MLTasks: newArr
    })
    
  }

  hasAtLeastOneElementChecked = (arr) => {
    for(var key of Object.keys(arr)){
      if(arr[key])
        return true
    }
    return false
  }

  selectUnselectAll = (arr) => {
    console.log("buttnon clicked")
    var newArr = {}
    if(this.hasAtLeastOneElementChecked(arr)){
      //unselect all
      for(let key of Object.keys(arr)){
        newArr[key] = false
      }
    }
    else{
      //select all
      for(let key of Object.keys(arr)){
        newArr[key] = true
      }
    }
    this.setState({
      EOTasks: newArr
    })
  }

  render(){
    const {classes} = this.props;
    const EOTasksElements = []
    for(var key of Object.keys(this.state.EOTasks)){
      EOTasksElements.push(
        <FormControlLabel key = {key}
          control={<Checkbox checked={this.state.EOTasks[key]} className={classes.checkbox} onChange={this.handleChangeEOTask} name={key} />}
          label={key}
        />
      )
    }
    return (
        <div className={classes.root}>
          <CssBaseline />
          <AppBarAiTLAS></AppBarAiTLAS>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <Toolbar />
            <div className={classes.drawerContainer}>
              <Typography gutterBottom className={classes.nameFilterItem}>EO task </Typography> 
              <Button onClick={()=>this.selectUnselectAll(this.state.EOTasks)} className = {classes.unselectAll}>{this.hasAtLeastOneElementChecked(this.state.EOTasks)? "unselect all":"select all"}</Button>
              
              <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup>
                {EOTasksElements}
              </FormGroup>
            </FormControl>
            {/* <Divider className={classes.divider}/> */}
              <Typography gutterBottom className={classes.nameFilterItem}>Num. of images</Typography>
              <Typography>{this.state.num_images_value[0]} - {this.state.num_images_value[1]}</Typography>
              <Slider
                className={classes.slider}
                value={this.state.num_images_value}
                onChange={this.handleChangeNumImages}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                max={this.state.maxImages}
                min={this.state.minImages}
              />
              {/* <Divider className={classes.divider}/> */}
              
              <Typography gutterBottom className={classes.nameFilterItem}>ML task</Typography>
              <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={this.state.MLTasks["binary classification"]} className={classes.checkbox} onChange={this.handleChangeMLTask} name="binary classification" />}
                  label="binary classification"
                />
                <FormControlLabel
                  control={<Checkbox checked={this.state.MLTasks["multi-class classification"]} className={classes.checkbox} onChange={this.handleChangeMLTask} name="multi-class classification" />}
                  label="multi-class classification"
                />
                <FormControlLabel
                  control={<Checkbox checked={this.state.MLTasks["multi label classification"]} className={classes.checkbox} onChange={this.handleChangeMLTask} name="multi label classification" />}
                  label="multi label classification"
                />
              </FormGroup>
            </FormControl>
            {/* <Divider className={classes.divider}/> */}
            <Typography id="range-slider" gutterBottom className={classes.nameFilterItem}>Num. of labels</Typography>
              <Typography>{this.state.num_labels_value[0]} - {this.state.num_labels_value[1]}</Typography>
              <Slider
                className={classes.slider}
                value={this.state.num_labels_value}
                onChange={this.handleChangeNumLabels}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                max={this.state.maxLabels}
                min={this.state.minLabels}
              />
              
              <Typography id="range-slider" gutterBottom className={classes.nameFilterItem}>Image size in pixels</Typography>
                <TextField
                  id="outlined-number"
                  value = {this.state.image_size_value}
                  placeholder = "e.g. <=256x256, 20x20-30x30"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e)=>{this.setState({image_size_value: String(e.target.value)})}}
                  inputProps={{style: {color: 'white'}}}
                  className = {classes.imageSizeInput}
                  error = {(!/^(([<>]=?)?[1-9]\d*x[1-9]\d*|[1-9]\d*x[1-9]\d*-[1-9]\d*x[1-9]\d*)$/.test(this.state.image_size_value)&&this.state.image_size_value!=="")}
                  helperText={/^(([<>]=?)?[1-9]\d*x[1-9]\d*|[1-9]\d*x[1-9]\d*-[1-9]\d*x[1-9]\d*)$/.test(this.state.image_size_value)||this.state.image_size_value==="" ? '' : 'Invalid Input Format'}

                  // /^(\d+(\.\d)?\d*(-\d+(\.\d)?\d*)?|[><]=?\d+(\.\d)?\d*)$/
                />
              <br/>
              <Button onClick = {this.handleFilterReset} className = {classes.refreshButton}><Refresh className={classes.refreshIcon} fontSize = "small"/>Reset filter</Button>
              <Button className={classes.filterButton} onClick={this.handleFilterClick} disabled={!/^(([<>]=?)?[1-9]\d*x[1-9]\d*|[1-9]\d*x[1-9]\d*-[1-9]\d*x[1-9]\d*)$/.test(this.state.image_size_value)&&this.state.image_size_value!==""}>Filter</Button>
            </div>
          </Drawer>
          <main className={classes.content}>
            <Toolbar />
            <DatasetGrid filteredDatasets = {this.state.filteredDatasets}/>
          </main>
        </div>
        
      );
  }
}
export default withStyles(useStyles)(ClippedDrawer)