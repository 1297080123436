import { red } from "@material-ui/core/colors";

export function myStyles(theme){
    const drawerWidth = 350;
    return {
        root: {
            display: 'flex',
            flexGrow: 1
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,    
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerContainer: {
            overflowX: 'hidden',
            textAlign: 'center',
            height: '100%!important'
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        paperDataset: {
            // padding: theme.spacing(2),
            textAlign: 'center',
            color: 'grey',
            // height: '500px',
            padding: 20,
            margin: 0
        },
        paper: {
            // textAlign: 'center',
        },
        aboutPaper: {
            padding: 20,
            textAlign: 'center'
        },
        datasetThumbnail: {
            width: '80%'
        },
        tasksPanel:{
            // backgroundColor: 'red'
        },
        datasetImage: {
            width: '90%',
            paddingLeft: 24,
            marginTop: 30
        },
        yellowSpan: {
            color: '#f0ba3c'
        },
        citationDiv: {
            backgroundColor: '#325f6d',
            // backgroundColor: '#2b517b',
            padding: 40,
            margin: 24
        },
        copyToClipboardElement:{
            backgroundColor: '#325f6d!important',
            float: 'right',
            "&:hover": {
                color: 'lightgrey!important',
            }
        },
        slider:{
            width: '200px!important',
            color: '#f0ba3c'
        },
        imageSizeInput: {
            marginLeft: '15px',
            width: '260px',
        },
        imageSizeTimes:{
            fontSize: '13pt',
            padding: '10px'
        }, 
        filterButton:{
            width: 240,
            backgroundColor: '#199ac1',
            color: '#ededed',
            "&:hover": {
                backgroundColor: '#0d7ea1',
                color: '#ededed'
            },
            padding:10
        },
        copyrightDiv: {
            position: 'fixed',
            bottom: 0,
            textAlign: 'center',
            marginTop: '20px'
        },
        copyright:{
            color: '#ededed',
            fontSize: '13pt'
        },  
        labelDistributionButton:{
            color: '#ededed',
            "&:hover": {
                color: '#f0ba3c!important'
            },
        },
        checkbox:{
            padding: 1,
        },
        divider: {
            marginBottom: 4,
        },
        nameFilterItem: {
            fontSize: 15,
            fontWeight: 700,
            color: '#f0ba3c'
        },
        refreshButton: {
            color: '#b5b5b5!important'
        },
        refreshIcon: {
            color: '#b5b5b5!important'
        },
        plot: {
            textAlign: 'center'
        },
        unselectAll:{
            display: 'inline',
            color: '#b5b5b5!important',
            float: 'left',
            marginLeft: 41,
            fontSize: 11
        }
    }
}