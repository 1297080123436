import React from 'react';
import {myStyles} from '../Utils/customTemplate.js'
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import logo from '../images/logo-cropped.png';
import BVLlogo from '../images/BVL_white_logo.png';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const useStyles = (theme => (myStyles(theme)));

class AppBarAiTLAS extends React.Component{
    render(){
      const {classes} = this.props;
        return (
            <AppBar position="fixed" className={classes.appBar}>  
            <Toolbar>
              <Link to="/datasets"> <img src = {logo} alt = "" style={{width:'50px', paddingTop:'5px' }}></img> </Link>
              <Typography variant="h6" noWrap>
                &nbsp; &nbsp; AiTLAS Semantic Data Catalog
              </Typography>
              <a href="https://bvlabs.ai/" target="_blank" style={{position: 'absolute', right: '10px'}}><img src = {BVLlogo} alt = "" style={{width:'90px', padding:'0px'}}></img></a>
              <Link style={{position: 'absolute', right: '120px', textDecoration: 'none', fontSize: 20, color: 'white'}} to="/about">About</Link>
            </Toolbar>
          </AppBar>
        )
    }

}
export default withStyles(useStyles)(AppBarAiTLAS)