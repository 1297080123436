import React from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import ClippedDrawer from './ClippedDrawer';
import Dataset from './Dataset';
import About from './About';

export default class RouterC extends React.Component{
    render(){
        return (
            <Router>
                <div>
                  <Switch>
                      <Route exact path='/' component={ClippedDrawer} />
                      <Route path='/datasets' component={ClippedDrawer} />
                      <Route exact path='/dataset' component={Dataset} />
                      <Route exact path='/dataset/:id' component={Dataset} />
                      <Route path='/about' component={About} />
                  </Switch>
                </div>
              </Router>
        );
    }
}