import React from 'react';
import {myStyles} from '../Utils/customTemplate.js'
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

const http = require('http')

const useStyles = (theme => (myStyles(theme)));

class DatasetGrid extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            datasets: [],
            filteredDatasets: this.props.filteredDatasets
        }
        
    }
    componentDidMount(){
        this.getDatasets()
    }

    componentDidUpdate(prevProps){
        if(prevProps!==this.props){
            this.setState({
                filteredDatasets: this.props.filteredDatasets
            }, ()=>{
                this.getDatasets()
            })
        }
    }

    getFilteredDatasets(){
        if(this.state.filteredDatasets==="N/A") return ""
        if(this.state.filteredDatasets.length>0){
            var str = "FILTER (?dataset_id in ("
            for(var i = 0; i<this.state.filteredDatasets.length; i++){
                str+="'"+this.state.filteredDatasets[i]+"'"
                if(i<this.state.filteredDatasets.length-1)
                str+=","
            }
            str += "))"
            return str
        }
        else{
            return ""
        }
    }
   
    getDatasets(){
        try{
            if(this.state.filteredDatasets==="N/A" || this.state.filteredDatasets.length>0){
                var query = `
                PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
                PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
                PREFIX xsd: <http://www.w3.org/2001/XMLSchema#> 
                PREFIX dc: <http://purl.org/dc/elements/1.1/>
                SELECT DISTINCT ?dataset_title ?dataset_id (GROUP_CONCAT(?EOtaskLabel;SEPARATOR=", ") AS ?tasks)
                WHERE{
                ?dataset_resource rdf:type <http://www.AiTLASOntology.com/EO_image_dataset>.
                ?dataset_resource dc:title ?dataset_title .
                ?dataset_resource dc:identifier ?dataset_id . `+this.getFilteredDatasets()+`
                ?EOtask <http://purl.obolibrary.org/obo/IAO_0000136> ?dataset_resource .
                ?EOtask rdf:type <http://www.AiTLASOntology.com/EO_task> .
                ?EOtask rdfs:label ?EOtaskLabel . 
                }
                group by ?dataset_title ?dataset_id    
                order by ?dataset_title        
                `
            
                var fuseki_req = "http://212.44.107.112:3030/eodata/query?query="+encodeURIComponent(query)
                http.get(fuseki_req, (resp) => {
                    let data = '';
                
                    // A chunk of data has been received.
                    resp.on('data', (chunk) => {
                        data += chunk;
                    });
                
                    // The whole response has been received. Print out the result.
                    resp.on('end', () => {
                        var arr = []
                        var json = JSON.parse(data)
                        if(json.results && json.results.bindings.length>0){
                            json.results.bindings.forEach(element=>{
                                var dataset = {}
                                dataset.title = element.dataset_title.value
                                dataset.id = element.dataset_id.value
                                dataset.tasks = element.tasks.value
                                arr.push(dataset)
                            })
                            this.setState({
                                datasets: arr
                            })
                        }
                        else{
                            console.log("no data data grid")
                            this.setState({
                                datasets: []
                            })
                        }
                    });
                })
                .on("error", (err) => {
                    console.log(err)
                });
            }
            else{
                this.setState({
                    datasets:[]
                })
            }
            
            
            
        }
        catch(err){
            console.log(err)
        }
    }

  
    render(){
        const {classes} = this.props;
        const datasets = []
        if(this.state.filteredDatasets==="N/A" || this.state.datasets.length>0){
            this.state.datasets.forEach(dataset=>{
                var img_src = require("../images/"+dataset.id+".jpeg").default
                var path = "/dataset/"+dataset.id
                datasets.push(
                    <Grid item xs={12} sm={6} md={4} xl={3} key = {dataset.id}>
                        <Link to={path} style={{ textDecoration: 'none' }} onClick={() => {console.info(dataset.id)}} target="_blank">
                            <Paper className={classes.paperDataset} >
                                <img className = {classes.datasetThumbnail} key = {dataset.id} src={img_src} alt = ""/>
                                <br/>
                                <br/>
                                <Typography variant="h5" component="h3" noWrap style={{color: '#f0ba3c'}}>{dataset.title}</Typography>
                                <br/>
                                <div className = {classes.tasksPanel}>
                                    <Typography> {dataset.tasks}</Typography>
                                </div>
                                
                            </Paper>
                        </Link>
                        
                    </Grid>
                )
            })
        }
        else {
            datasets.push(<Typography variant="h4" component="h4" noWrap style={{color: '#ededed'}}>No datasets match the filter.</Typography>)
        }
        
       return(
           <div>
               <Grid container spacing={3}>
                    {datasets}
                </Grid>
                {/* <div className={classes.copyrightDiv}>
                    <span className={classes.copyright}>Copyright &copy;<LinkUI href="https://bvlabs.ai/" target="_blank" style={{textDecoration: 'none', color: '#ededed'}} >Bias Variance Labs</LinkUI> 2021</span>
                </div> */}
                
           </div>
       )
    }
}
export default withStyles(useStyles)(DatasetGrid)