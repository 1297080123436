import React from 'react';
import { myStyles } from '../Utils/customTemplate.js'
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AppBarAiTLAS from './AppBarAiTLAS.jsx';
import FileCopy from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Bookmark from '@material-ui/icons/Bookmark';
import Button from '@material-ui/core/Button';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import DownloadIcon from '@material-ui/icons/ArrowDownward';
import Link from '@material-ui/core/Link';
import Plot from 'react-plotly.js';
import desc from '../label_distributions.json'

const http = require('http')
const useStyles = (theme => (myStyles(theme)));

const config = {
    modeBarButtonsToRemove: ['autoScale2d', 'zoomIn2d', 'zoomOut2d', 'pan2d ', 'lasso2d', 'select2d', 'resetScale2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toggleSpikelines', 'sendDataToCloud'],
    toImageButtonOptions: { width: 1000, scale: 1, format: 'jpeg' },
    responsive: true,
    displaylogo: false
}



function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


class Dataset extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            id: "",
            title: "N/A",
            MLtask: "N/A",
            links: "N/A",
            tasks: "N/A",
            // labels: "N/A",
            num_images: "N/A",
            image_size: "N/A",
            license: "N/A",
            volume: "N/A",
            downloads: "N/A",
            year: "N/A",
            spatial_resolution: "N/A",
            temporal: "N/A",
            spatial: "N/A",
            citаtions: [],
            source: "N/A",
            datatype: "",
            paper_titles: [],
            paper_links: [],
            openCopyToClipboardSnackBar: false

        }
    }

    componentDidMount() {
        this.setState({
            id: this.props.match.params.id
        }, () => {
            this.getDatasetTitleLinksTasksLabels();
            this.getImageDetails();
            this.getDistributionDetails();
            this.getDCATInfo();
            this.getPublicationInfo();
        })

    }


    getDatasetTitleLinksTasksLabels() {
        if (!this.state.id || this.state.id === "") return
        var query = `
        PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
        PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
        PREFIX xsd: <http://www.w3.org/2001/XMLSchema#> 
        PREFIX dc: <http://purl.org/dc/elements/1.1/>
        PREFIX aitlas: <http://www.AiTLASOntology.com/>
        SELECT DISTINCT ?dataset_title ?dataset_id ?MLTask_label (GROUP_CONCAT(DISTINCT ?dataset_link;SEPARATOR=", ") AS ?links) (GROUP_CONCAT(DISTINCT ?EOtaskLabel;SEPARATOR=", ") AS ?tasks) (GROUP_CONCAT(DISTINCT ?dataset_label;SEPARATOR=", ") AS ?labels)
        WHERE{
          ?dataset_resource rdf:type aitlas:EO_image_dataset.
          ?dataset_resource dc:title ?dataset_title .
          ?dataset_resource dc:identifier "`+ this.state.id + `" .
          ?EOtask <http://purl.obolibrary.org/obo/IAO_0000136> ?dataset_resource .
          ?EOtask rdf:type aitlas:EO_task .
          ?EOtask rdfs:label ?EOtaskLabel . 
          OPTIONAL {?dataset_resource aitlas:link ?dataset_link .}
          OPTIONAL {
    		?dataset_resource aitlas:has_labelset ?dataset_labelset .
          	?dataset_labelset <http://purl.obolibrary.org/obo/BFO_0000051> ?label .
    		?label dc:title ?dataset_label .
 			 }
          ?MLTask <http://purl.obolibrary.org/obo/IAO_0000136> ?dataset_resource .
          ?MLTask rdf:type ?MLTaskclass. 
          ?MLTask rdfs:label ?MLTask_label .
          ?MLTaskclass rdfs:subClassOf <http://www.ontodm.com/OntoDM-core/OntoDM_000098>
        
        }
        group by ?dataset_title ?dataset_id  ?MLTask_label   
        `

        var fuseki_req = "http://212.44.107.112:3030/eodata/query?query=" + encodeURIComponent(query)
        http.get(fuseki_req, (resp) => {
            let data = '';

            // A chunk of data has been received.
            resp.on('data', (chunk) => {
                data += chunk;
            });

            // The whole response has been received. Print out the result.
            resp.on('end', () => {
                var json = JSON.parse(data)
                if (json.results && json.results.bindings[0]) {
                    let element = json.results.bindings[0]

                    this.setState({
                        title: this.parseEntry(element.dataset_title),
                        MLtask: this.parseEntry(element.MLTask_label) !== "N/A" ? this.parseEntry(element.MLTask_label).split("_").pop() : "N/A",
                        links: this.parseEntryLink(element.links),
                        tasks: this.parseEntry(element.tasks),
                        // labels: this.parseEntry(element.labels)
                    })
                }
                else {
                    return console.log("No results. ")
                }
            });
        })
            .on("error", (err) => {
                console.log(err)
            });
    }

    parseEntry(entry) {
        return entry && entry.value ? entry.value : "N/A"
    }

    parseEntryLink(entry) {
        return entry && entry.value ? entry.value.split(",") : "N/A"
    }

    getImageDetails() {
        if (!this.state.id || this.state.id === "") return
        var query = `
        PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
        PREFIX dc: <http://purl.org/dc/elements/1.1/>
        PREFIX aitlas: <http://www.AiTLASOntology.com/>
        SELECT DISTINCT ?num_images ?datatype ?source (GROUP_CONCAT(DISTINCT ?image_size;SEPARATOR=", ") AS ?image_sizes)
        WHERE{
          ?dataset_resource rdf:type aitlas:EO_image_dataset.
          ?dataset_resource dc:identifier "`+ this.state.id + `" .
          ?dataset_resource aitlas:number_of_images ?num_images .
          ?dataset_resource aitlas:datatype ?datatype .
          OPTIONAL {?dataset_resource aitlas:source ?source . }
          ?dataset_resource aitlas:image_size ?image_size .

        }        
        GROUP BY ?num_images ?datatype ?source
        `

        var fuseki_req = "http://212.44.107.112:3030/eodata/query?query=" + encodeURIComponent(query)
        http.get(fuseki_req, (resp) => {
            let data = '';

            // A chunk of data has been received.
            resp.on('data', (chunk) => {
                data += chunk;
            });

            // The whole response has been received. Print out the result.
            resp.on('end', () => {
                var json = JSON.parse(data)
                if (json.results && json.results.bindings[0]) {
                    let element = json.results.bindings[0]

                    this.setState({
                        num_images: this.parseEntry(element.num_images),
                        image_size: this.parseEntry(element.image_sizes),
                        datatype: this.parseEntry(element.datatype),
                        source: this.parseEntry(element.source)
                    })
                }
                else {
                    return console.log("No results. ")
                }
            });
        })
            .on("error", (err) => {
                console.log(err)
            });
    }

    getDistributionDetails() {
        if (!this.state.id || this.state.id === "") return
        var query = `
        PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
        PREFIX dc: <http://purl.org/dc/elements/1.1/>
        PREFIX aitlas: <http://www.AiTLASOntology.com/>
        PREFIX ai4eu: <http://www.ai4eu.eu/ontologies/core#>
        SELECT DISTINCT ?license ?volume (GROUP_CONCAT(DISTINCT ?download;SEPARATOR=", ") AS ?downloads)
        WHERE{
        ?dataset_resource rdf:type aitlas:EO_image_dataset.
        ?dataset_resource dc:identifier "`+ this.state.id + `" .
        ?dataset_resource ai4eu:distributed_as ?distribution .
        OPTIONAL {?distribution ai4eu:download ?download . }
        OPTIONAL {?distribution ai4eu:under_license ?license . }
        OPTIONAL {?distribution aitlas:volume_in_MB ?volume . }
        }
        GROUP by ?license ?volume
        `

        var fuseki_req = "http://212.44.107.112:3030/eodata/query?query=" + encodeURIComponent(query)
        http.get(fuseki_req, (resp) => {
            let data = '';

            // A chunk of data has been received.
            resp.on('data', (chunk) => {
                data += chunk;
            });

            // The whole response has been received. Print out the result.
            resp.on('end', () => {
                var json = JSON.parse(data)
                if (json.results && json.results.bindings[0]) {
                    let element = json.results.bindings[0]

                    this.setState({
                        license: this.parseEntry(element.license),
                        volume: this.parseEntry(element.volume),
                        downloads: this.parseEntry(element.downloads)
                    })
                }
                else {
                    return console.log("No results. ")
                }
            });
        })
            .on("error", (err) => {
                console.log(err)
            });
    }

    getDCATInfo() {
        if (!this.state.id || this.state.id === "") return
        var query = `
        PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
        PREFIX xsd: <http://www.w3.org/2001/XMLSchema#> 
        PREFIX dc: <http://purl.org/dc/elements/1.1/>
        PREFIX aitlas: <http://www.AiTLASOntology.com/>
        PREFIX dcterms: <http://purl.org/dc/terms/>
        SELECT DISTINCT  ?dataset_id ?year ?spatial_resolution ?temporal_v ?spatial_v
        WHERE{
        ?dataset_resource rdf:type aitlas:EO_image_dataset.
        ?dataset_resource dc:identifier "`+ this.state.id + `" .
        ?dataset_resource aitlas:same_as ?dcatDataset . 
        ?dcatDataset dcterms:issued ?year .
        OPTIONAL {
            ?dcatDataset dcterms:temporal ?temporal .
            ?temporal aitlas:has_value ?temporal_v .
        }
        OPTIONAL {
            ?dcatDataset dcterms:spatial ?spatial . 
            ?spatial aitlas:has_value ?spatial_v . 
        }
        OPTIONAL {?dcatDataset <http://www.w3.org/ns/dcat#spatialResolutionInMeters> ?spatial_resolution .}
        
        }
        `
        var fuseki_req = "http://212.44.107.112:3030/eodata/query?query=" + encodeURIComponent(query)
        http.get(fuseki_req, (resp) => {
            let data = '';

            // A chunk of data has been received.
            resp.on('data', (chunk) => {
                data += chunk;
            });

            // The whole response has been received.
            resp.on('end', () => {
                var json = JSON.parse(data)
                if (json.results && json.results.bindings[0]) {
                    let element = json.results.bindings[0]

                    this.setState({
                        year: this.parseEntry(element.year),
                        spatial_resolution: this.parseEntry(element.spatial_resolution),
                        temporal: this.parseEntry(element.temporal_v),
                        spatial: this.parseEntry(element.spatial_v)
                    })
                }
                else {
                    return console.log("No results. ")
                }
            });
        })
            .on("error", (err) => {
                console.log(err)
            });
    }

    getPublicationInfo() {
        if (!this.state.id || this.state.id === "") return
        var query = `
        PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
        PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
        PREFIX xsd: <http://www.w3.org/2001/XMLSchema#> 
        PREFIX dc: <http://purl.org/dc/elements/1.1/>
        PREFIX aitlas: <http://www.AiTLASOntology.com/>
        SELECT DISTINCT  ?dataset_id ?publication_label ?citation ?link (GROUP_CONCAT(DISTINCT ?author_label;SEPARATOR=", ") AS ?autors)
        WHERE{
          ?dataset_resource rdf:type aitlas:EO_image_dataset.
          ?dataset_resource dc:identifier "`+ this.state.id + `" .
          ?dataset_resource aitlas:described_in ?publication . 
          ?publication rdfs:label ?publication_label . 
          ?publication aitlas:denoted_by ?citation .
          ?publication aitlas:link ?link .
          ?author <http://xmlns.com/foaf/0.1/publications> ?publication .
          ?author rdfs:label ?author_label .
        }
        group by ?dataset_id ?publication_label ?citation ?link
        `

        var fuseki_req = "http://212.44.107.112:3030/eodata/query?query=" + encodeURIComponent(query)
        http.get(fuseki_req, (resp) => {
            let data = '';

            // A chunk of data has been received.
            resp.on('data', (chunk) => {
                data += chunk;
            });

            // The whole response has been received.
            resp.on('end', () => {
                var json = JSON.parse(data)
                let titles = []
                let links = []
                let citations = []
                if (json.results && json.results.bindings) {
                    json.results.bindings.forEach(element => {
                        titles.push(element.publication_label.value)
                        links.push(element.link.value)
                        citations.push(element.citation.value)
                    })
                    this.setState({
                        paper_titles: titles,
                        paper_links: links,
                        citations: citations
                    })
                }
                else {
                    return console.log("No results. ")
                }
            });
        })
            .on("error", (err) => {
                console.log(err)
            });
    }

    handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({
            openCopyToClipboardSnackBar: false
        })
    };


    getLicenceInfo = () => {
        if (this.state.license !== "N/A") {
            if (this.state.license.startsWith("http")) {
                return <Typography>The dataset is published under the <b><Link href={this.state.license} target="_blank" style={{ color: '#f0ba3c', textDecoration: 'none' }}>{this.state.license}</Link></b> license.</Typography>
            }
            else {
                return <Typography>{this.state.license}</Typography>
            }
        }
        else {
            return <Typography>N/A</Typography>
        }
    }

    getRelatedLinksElements = () => {
        if (this.state.links === "N/A") return "N/A";
        var arr = []
        this.state.links.forEach(link => {
            arr.push(<Link href={link} style={{ color: 'white' }} target="_blank">{link}</Link>)
        })
        return arr
    }

    hasLabelDistribution = () => {
        return desc[this.state.id] && desc[this.state.id].label_distribution ? true : false
    }
    hasTrainLabelDistribution = () => {
        let v = this.props.match.params.id
        return desc[v] && desc[v]["train"] && desc[v]["train"].label_distribution ? true : false
    }
    hasTestLabelDistribution = () => {
        let v = this.props.match.params.id
        return desc[v] && desc[v]["test"] && desc[v]["test"].label_distribution ? true : false
    }

    hasValidationLabelDistribution = () => {
        let v = this.props.match.params.id
        return desc[v] && desc[v]["validation"] && desc[v]["validation"].label_distribution ? true : false
    }

    getLabelsAndCounts = (mode) => {
        let label_dist = {}
        let id = this.props.match.params.id

        if (mode === "train") {
            label_dist = desc[id]["train"].label_distribution
        }
        else if (mode === "test") {
            label_dist = desc[id]["test"].label_distribution
        }
        else if (mode === "validation") {
            label_dist = desc[id]["validation"].label_distribution
        }
        else if (mode === "all") {
            label_dist = desc[id].label_distribution
        }

        let labels = []
        let counts = []
        for (var key of Object.keys(label_dist)) {
            labels.push(key)
            counts.push(label_dist[key])
        }
        return [labels, counts]
    }

    getLabels = () => {
        let id = this.props.match.params.id
        if (desc[id] && desc[id].labels) {
            return desc[id].labels.toString()
        }
        return false
    }

    getLabelDistributionInCSV(dict) {
        let str = "label,count\n"
        for (var key of Object.keys(dict)) {
            str += key + "," + dict[key] + "\n"
        }
        return str
    }

    getNumImages = () => {
        let id = this.props.match.params.id
        if (desc[id] && desc[id]["#images"] !== undefined) {
            return desc[id]["#images"]
        }
        else if (desc[id] && desc[id].train && desc[id].train["#images"] !== undefined && desc[id] && desc[id].validation && desc[id].validation["#images"] !== undefined && desc[id].test && desc[id].test["#images"] !== undefined) {
            return desc[id].train["#images"] + desc[id].validation["#images"] + desc[id].test["#images"]
        }
        else if (desc[id] && desc[id].train && desc[id].train["#images"] !== undefined && desc[id].test && desc[id].test["#images"] !== undefined) {
            return desc[id].train["#images"] + desc[id].test["#images"]
        }
    }

    getNumImagesTrain = () => {
        let id = this.props.match.params.id
        if (desc[id] && desc[id].train && desc[id].train["#images"] !== undefined) {
            return desc[id].train["#images"]
        }
        return false
    }

    getNumImagesTest = () => {
        let id = this.props.match.params.id
        if (desc[id] && desc[id].test && desc[id].test["#images"] !== undefined) {
            return desc[id].test["#images"]
        }
        return false
    }

    getNumImagesValidation = () => {
        let id = this.props.match.params.id
        if (desc[id] && desc[id].validation && desc[id].validation["#images"] !== undefined) {
            return desc[id].validation["#images"]
        }
        return false
    }
    render() {
        var img_src;
        if (this.state.id)
            img_src = require("../images/" + this.state.id + ".jpeg").default
        else img_src = "#"
        // var rdf_src;
        // if(this.state.id)
        // rdf_src = require("../annotations/"+this.state.id+".rdf").default
        // else rdf_src = "#"
        const { classes } = this.props;
        var citations = []
        var publications = []
        var downloads = []
        let id = this.props.match.params.id
        if (this.state.citations) {
            this.state.citations.forEach(cite => {
                let cite_text = cite.replaceAll("}, ", "},").replaceAll("},", "},\n\t").replace(/}\s*}/, "}\n}").replace("title", "\n\ttitle")
                citations.push(
                    <div className={classes.citationDiv}>
                        <Typography wrap><pre>{cite_text}</pre>
                            <Tooltip title="Copy to Clipboard">
                                <FileCopy className={classes.copyToClipboardElement} onClick={() => {
                                    navigator.clipboard.writeText(cite_text)
                                    this.setState({ openCopyToClipboardSnackBar: true })
                                }}></FileCopy>
                            </Tooltip>
                        </Typography>

                    </div>
                )
            })
        }
        if (this.state.paper_titles) {
            for (let i = 0; i < this.state.paper_titles.length; i++) {
                publications.push(
                    <Link href={this.state.paper_links[i]} target="_blank" style={{ color: '#ededed', textDecoration: 'none' }}>
                        <ListItem button>
                            <ListItemIcon>
                                <Bookmark />
                            </ListItemIcon>
                            <ListItemText primary={this.state.paper_titles[i]} />
                        </ListItem>
                    </Link>
                )
            }
        }
        if (this.state.downloads !== "N/A") {
            this.state.downloads.split(", ").forEach(download => {
                downloads.push(
                    <Link href={download} target="_blank" style={{ color: '#ededed', textDecoration: 'none' }}>
                        <ListItem button>
                            <ListItemIcon>
                                <ArrowForwardIos />
                            </ListItemIcon>
                            <ListItemText primary={download} />
                        </ListItem>
                    </Link>
                )
            })
        }



        return (
            <div>
                <AppBarAiTLAS></AppBarAiTLAS>
                <br /><br /><br /><br />
                <Grid container spacing={3} justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={10} lg={8}>
                        <br /><br />
                        <Paper className={classes.paper}>
                            <Grid container spacing={3} justifyContent="center" alignItems="center">
                                <Grid item xs={12} md={5} style={{ textAlign: 'center' }}>
                                    <img className={classes.datasetImage} key={this.state.id} src={img_src} alt="" />
                                    <Typography variant="h6" component="h2" noWrap>{this.state.title} image sample(s)</Typography>
                                    <br />
                                    <a href={"/annotations/" + this.state.id + ".rdf"} style={{ color: '#ededed', textDecoration: 'none' }} download>
                                        <Typography variant="h6" component="h2" noWrap> Download <span style={{ color: '#f0ba3c' }}>RDF metadata</span> </Typography>
                                    </a>
                                </Grid>
                                <Grid item xs={12} md={7} style={{ paddingRight: '30px' }}>
                                    <div style={{ textAlign: 'center' }} >
                                        <Typography variant="h5" component="h3" noWrap>{this.state.title}</Typography>
                                        <hr style={{ width: 200, border: '1px solid #f0ba3c' }}></hr>
                                    </div>
                                    <div>
                                        <Accordion defaultExpanded>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography className={classes.heading} variant="h6" component="h4">Description</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <List component="nav" style={{ width: '100%' }}>

                                                    <Typography>{desc[id].description}</Typography>

                                                </List>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography className={classes.heading} variant="h6" component="h4">Dataset details</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <List component="nav" style={{ width: '100%' }}>
                                                    {/* <Typography><b>No. of images:</b> {this.state.num_images}</Typography>   */}
                                                    <Typography><b>No. of images:</b> {this.getNumImages()}</Typography>
                                                    {this.getNumImagesTrain() ? <Typography><b>No. of images in the train set:</b> {this.getNumImagesTrain()}</Typography> : null}
                                                    {this.getNumImagesValidation() ? <Typography><b>No. of images in the validation set:</b> {this.getNumImagesValidation()}</Typography> : null}
                                                    {this.getNumImagesTest() ? <Typography><b>No. of images in the test set:</b> {this.getNumImagesTest()}</Typography> : null}
                                                    <Typography><b>Image size:</b> {this.state.image_size}</Typography>
                                                    <Typography><b>Image type:</b>{this.state.datatype !== "" && this.state.datatype !== "N/A" ? <span> {this.state.datatype}</span> : ""}</Typography>
                                                    {/* <Typography><b>Volume in MB: </b> {this.state.volume}</Typography>   */}
                                                    {desc[id].format !== undefined ? <Typography><b>Image format:</b> {desc[id].format}</Typography> : null}
                                                    <Typography><b>Year of publication: </b>{this.state.year}</Typography>
                                                    <Typography><b>Spatial resolution in meters: </b>{this.state.spatial_resolution}</Typography>
                                                    <Typography><b>Temporal coverage:</b> {this.state.temporal}</Typography>
                                                    <Typography><b>Spatial coverage: </b>{this.state.spatial}</Typography>
                                                    <Typography><b>Machine learning task: </b>{this.state.MLtask}</Typography>
                                                    <Typography><b>Related links: </b> {this.getRelatedLinksElements()}</Typography>
                                                </List>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography className={classes.heading} variant="h6" component="h4">AiTLAS toolbox</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <List component="nav" style={{ width: '100%' }}>

                                                    <Typography variant="h6" component="h4" paragraph>Click <Link href={"https://github.com/biasvariancelabs/aitlas/blob/master/aitlas/datasets/" + this.state.id.split("--")[0] + ".py"} target="_blank" style={{ color: '#f0ba3c', textDecoration: 'none' }}>here</Link> to access the dataset in the AiTLAS toolbox.</Typography>

                                                    {desc[id] && desc[id].notebook !== undefined && desc[id].notebook !== "null" ? <div> <br /><Typography variant="h6" component="h4" paragraph>Click <Link href={"https://github.com/biasvariancelabs/aitlas/blob/master/examples/" + desc[id].notebook} target="_blank" style={{ color: '#f0ba3c', textDecoration: 'none' }}>here</Link> to open an example of how the dataset can be used in AiTLAS.</Typography></div> : null}
                                                    <br />
                                                    {desc[id] && desc[id].config !== undefined && desc[id].config !== "null" ?
                                                        <Typography variant="h6" component="h4" paragraph>Click <Link href={this.state.id.split("--")[0].endsWith('big_earth_net') ? 'https://github.com/biasvariancelabs/aitlas/tree/master/configs/bigearthnet' : "https://github.com/biasvariancelabs/aitlas/tree/master/configs/" + this.state.id.split("--")[0]} target="_blank" style={{ color: '#f0ba3c', textDecoration: 'none' }}>here</Link> to access the predefined ML tasks for the dataset. </Typography> : null
                                                    }

                                                </List>
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography className={classes.heading} variant="h6" component="h4">EO labels</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <List component="nav" style={{ width: '100%' }}>
                                                    {
                                                        this.getLabels() ?
                                                            <div>
                                                                <Typography>{this.getLabels().replaceAll(",", ", ")}</Typography>
                                                                <br /><br />
                                                                <Typography>The dataset has <b>{this.getLabels().split(",").length} labels</b> in total.</Typography>
                                                            </div>
                                                            : <Typography>"N/A"</Typography>
                                                    }

                                                    {
                                                        desc[id] && desc[id].train && desc[id].train["min_num_labels"] ?
                                                            <div>
                                                                <br />
                                                                <Typography>Minimum number of labels per image in the train set: <b>{desc[id].train["min_num_labels"]}</b></Typography>
                                                            </div> :
                                                            null
                                                    }
                                                    {
                                                        desc[id] && desc[id].train && desc[id].train["max_num_labels"] ?
                                                            <Typography>Maximum number of labels per image in the train set: <b>{desc[id].train["max_num_labels"]}</b></Typography> :
                                                            null
                                                    }
                                                    {
                                                        desc[id] && desc[id].train && desc[id].train["avg_num_labels"] ?
                                                            <Typography>Average number of labels per image in the train set: <b>{desc[id].train["avg_num_labels"]}</b></Typography> :
                                                            null
                                                    }

                                                    {
                                                        desc[id] && desc[id].test && desc[id].test["min_num_labels"] ?
                                                            <Typography>Minimum number of labels per image in the test set: <b>{desc[id].test["min_num_labels"]}</b></Typography> :
                                                            null
                                                    }
                                                    {
                                                        desc[id] && desc[id].test && desc[id].test["max_num_labels"] ?
                                                            <div>
                                                                <br />
                                                                <Typography>Maximum number of labels per image in the test set: <b>{desc[id].test["max_num_labels"]}</b></Typography>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        desc[id] && desc[id].test && desc[id].test["avg_num_labels"] ?
                                                            <Typography>Average number of labels per image in the test set: <b>{desc[id].test["avg_num_labels"]}</b></Typography> :
                                                            null
                                                    }
                                                    {
                                                        desc[id] && desc[id]["min_num_labels"] !== undefined ?
                                                            <div>
                                                                <br />
                                                                <Typography>Minimum number of labels per image: <b>{desc[id]["min_num_labels"]}</b></Typography>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        desc[id] && desc[id]["max_num_labels"] !== undefined ?
                                                            <Typography>Maximum number of labels per image: <b>{desc[id]["max_num_labels"]}</b></Typography> :
                                                            null
                                                    }
                                                    {
                                                        desc[id] && desc[id]["avg_num_labels"] !== undefined ?
                                                            <Typography>Average number of labels per image: <b>{desc[id]["avg_num_labels"]}</b></Typography> :
                                                            null
                                                    }



                                                    {/* {this.state.labels!=="N/A"? <div><Typography>{this.state.labels}<br/><br/>The datаset has <b>{this.state.labels.split(", ").length} labels </b> in total. </Typography></div>: "N/A"} */}
                                                </List>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography className={classes.heading} variant="h6" component="h4">EO task</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <List component="nav" style={{ width: '100%' }}>

                                                    {this.state.tasks !== "N/A" ? <div><Typography>{this.state.tasks}<br /><br /> </Typography></div> : "N/A"}
                                                </List>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography className={classes.heading} variant="h6" component="h4">Source</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <List component="nav" style={{ width: '100%' }}>

                                                    {this.state.source !== "N/A" ? <div><Typography>{this.state.source}</Typography></div> : ""}

                                                </List>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography className={classes.heading} variant="h6" component="h4">Download</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <List component="nav" style={{ width: '100%' }}>

                                                    {downloads.length > 0 ? downloads : <Typography>N/A</Typography>}
                                                </List>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography className={classes.heading} variant="h6" component="h4">License</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <List component="nav" style={{ width: '100%' }}>

                                                    {this.getLicenceInfo()}
                                                </List>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header"
                                            >
                                                <Typography className={classes.heading} variant="h6" component="h4">Publications</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <List component="nav" style={{ width: '100%' }}>

                                                    {publications.length > 0 ? publications : <Typography>N/A</Typography>}
                                                </List>
                                            </AccordionDetails>
                                        </Accordion>




                                    </div>

                                </Grid>
                                {
                                    this.hasLabelDistribution() ?
                                        <Grid item xs={12}>
                                            <Typography style={{ textAlign: 'center' }} variant="h6" component="h2">Label distribution</Typography>
                                            <hr style={{ width: 290, border: '1px solid #f0ba3c' }}></hr>
                                            <div className={classes.plot}>
                                                <Plot
                                                    data={
                                                        [{
                                                            type: 'bar',
                                                            y: this.getLabelsAndCounts("all")[0],
                                                            x: this.getLabelsAndCounts("all")[1],
                                                            orientation: 'h'
                                                        }]
                                                    }
                                                    layout={{
                                                        paper_bgcolor: 'rgba(0,0,0,0)',
                                                        plot_bgcolor: 'rgba(0,0,0,0)',
                                                        xaxis: {
                                                            gridcolor: 'rgba(86,86,86,0.4)',
                                                            title: 'Count'
                                                        },
                                                        font: {
                                                            size: 12,
                                                            color: "#ededed"
                                                        },
                                                        color: 'lifeExp',
                                                        height: 200 + this.getLabels().split(",").length * 14,
                                                        width: 700
                                                    }}
                                                    config={config}
                                                />
                                                <Button
                                                    href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(desc[id].label_distribution))}`}
                                                    download="label_distribution.json"
                                                    className={classes.labelDistributionButton}
                                                >
                                                    <DownloadIcon className={classes.labelDistributionButton} />
                                                    {`Download label distributions in JSON format`}
                                                </Button>
                                                <br />
                                                <Button
                                                    href={`data:text/json;charset=utf-8,${encodeURIComponent(this.getLabelDistributionInCSV(desc[id].label_distribution))}`}
                                                    download="label_distribution.csv"
                                                    className={classes.labelDistributionButton}
                                                >
                                                    <DownloadIcon className={classes.labelDistributionButton} />
                                                    {`Download label distributions in CSV format`}
                                                </Button>
                                            </div>

                                        </Grid>
                                        :
                                        null
                                }
                                {
                                    this.hasTrainLabelDistribution() ?
                                        <Grid item xs={12}>
                                            <Typography style={{ textAlign: 'center' }} variant="h6" component="h2">Label distribution on train set</Typography>
                                            <hr style={{ width: 290, border: '1px solid #f0ba3c' }}></hr>
                                            <div className={classes.plot}>
                                                <Plot
                                                    data={
                                                        [{
                                                            type: 'bar',
                                                            y: this.getLabelsAndCounts("train")[0],
                                                            x: this.getLabelsAndCounts("train")[1],
                                                            orientation: 'h'
                                                        }]
                                                    }
                                                    layout={{
                                                        paper_bgcolor: 'rgba(0,0,0,0)',
                                                        plot_bgcolor: 'rgba(0,0,0,0)',
                                                        xaxis: {
                                                            gridcolor: 'rgba(86,86,86,0.4)',
                                                            title: 'Count'
                                                        },
                                                        font: {
                                                            size: 12,
                                                            color: "#ededed"
                                                        },
                                                        color: 'lifeExp',
                                                        height: 200 + this.getLabels().split(",").length * 14,
                                                        width: 700
                                                    }}
                                                    config={config}
                                                />
                                                <Button
                                                    href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(desc[id].train.label_distribution))}`}
                                                    download="label_distribution_train.json"
                                                    className={classes.labelDistributionButton}
                                                >
                                                    <DownloadIcon className={classes.labelDistributionButton} />
                                                    {`Download label distributions on train set in JSON format`}
                                                </Button>
                                                <br />
                                                <Button
                                                    href={`data:text/json;charset=utf-8,${encodeURIComponent(this.getLabelDistributionInCSV(desc[id].train.label_distribution))}`}
                                                    download="label_distribution.csv"
                                                    className={classes.labelDistributionButton}
                                                >
                                                    <DownloadIcon className={classes.labelDistributionButton} />
                                                    {`Download label distributions on train set in CSV format`}
                                                </Button>
                                            </div>

                                        </Grid>
                                        :
                                        null
                                }
                                {
                                    this.hasValidationLabelDistribution() ?
                                        <Grid item xs={12}>
                                            <Typography style={{ textAlign: 'center' }} variant="h6" component="h2">Label distribution on validation set</Typography>
                                            <hr style={{ width: 290, border: '1px solid #f0ba3c' }}></hr>
                                            <div className={classes.plot}>
                                                <Plot
                                                    data={
                                                        [{
                                                            type: 'bar',
                                                            y: this.getLabelsAndCounts("validation")[0],
                                                            x: this.getLabelsAndCounts("validation")[1],
                                                            orientation: 'h'
                                                        }]
                                                    }
                                                    layout={{
                                                        paper_bgcolor: 'rgba(0,0,0,0)',
                                                        plot_bgcolor: 'rgba(0,0,0,0)',
                                                        xaxis: {
                                                            gridcolor: 'rgba(86,86,86,0.4)',
                                                            title: 'Count'
                                                        },
                                                        font: {
                                                            size: 12,
                                                            color: "#ededed"
                                                        },
                                                        color: 'lifeExp',
                                                        height: 200 + this.getLabels().split(",").length * 14,
                                                        width: 700
                                                    }}
                                                    config={config}
                                                />
                                                <Button
                                                    href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(desc[id].validation.label_distribution))}`}
                                                    download="label_distribution_test.json"
                                                    className={classes.labelDistributionButton}
                                                >
                                                    <DownloadIcon className={classes.labelDistributionButton} />
                                                    {`Download label distributions on validation set in JSON format`}
                                                </Button>
                                                <br />
                                                <Button
                                                    href={`data:text/json;charset=utf-8,${encodeURIComponent(this.getLabelDistributionInCSV(desc[id].validation.label_distribution))}`}
                                                    download="label_distribution.csv"
                                                    className={classes.labelDistributionButton}
                                                >
                                                    <DownloadIcon className={classes.labelDistributionButton} />
                                                    {`Download label distributions on validation set in CSV format`}
                                                </Button>
                                            </div>

                                        </Grid>
                                        :
                                        null
                                }
                                {
                                    this.hasTestLabelDistribution() ?
                                        <Grid item xs={12}>
                                            <Typography style={{ textAlign: 'center' }} variant="h6" component="h2">Label distribution on test set</Typography>
                                            <hr style={{ width: 290, border: '1px solid #f0ba3c' }}></hr>
                                            <div className={classes.plot}>
                                                <Plot
                                                    data={
                                                        [{
                                                            type: 'bar',
                                                            y: this.getLabelsAndCounts("test")[0],
                                                            x: this.getLabelsAndCounts("test")[1],
                                                            orientation: 'h'
                                                        }]
                                                    }
                                                    layout={{
                                                        paper_bgcolor: 'rgba(0,0,0,0)',
                                                        plot_bgcolor: 'rgba(0,0,0,0)',
                                                        xaxis: {
                                                            gridcolor: 'rgba(86,86,86,0.4)',
                                                            title: 'Count'
                                                        },
                                                        font: {
                                                            size: 12,
                                                            color: "#ededed"
                                                        },
                                                        color: 'lifeExp',
                                                        height: 200 + this.getLabels().split(",").length * 14,
                                                        width: 700
                                                    }}
                                                    config={config}
                                                />
                                                <Button
                                                    href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(desc[id].test.label_distribution))}`}
                                                    download="label_distribution_test.json"
                                                    className={classes.labelDistributionButton}
                                                >
                                                    <DownloadIcon className={classes.labelDistributionButton} />
                                                    {`Download label distributions on test set in JSON format`}
                                                </Button>
                                                <br />
                                                <Button
                                                    href={`data:text/json;charset=utf-8,${encodeURIComponent(this.getLabelDistributionInCSV(desc[id].test.label_distribution))}`}
                                                    download="label_distribution.csv"
                                                    className={classes.labelDistributionButton}
                                                >
                                                    <DownloadIcon className={classes.labelDistributionButton} />
                                                    {`Download label distributions on test set in CSV format`}
                                                </Button>
                                            </div>

                                        </Grid>
                                        :
                                        null
                                }
                                {
                                    citations.length > 0 ?
                                        <Grid item xs={12}>
                                            <Typography style={{ textAlign: 'center' }} variant="h6" component="h2">If you use this dataset, <b>cite</b> the following:</Typography>
                                            <hr style={{ width: 400, border: '1px solid #f0ba3c' }}></hr>
                                            {citations}
                                        </Grid> :
                                        null
                                }


                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                {/* <div className={classes.copyrightDiv}>
                    <br/>
                    <span className={classes.copyright}>Copyright &copy;<Link href="https://bvlabs.ai/" target="_blank" style={{textDecoration: 'none', color: '#ededed'}} >Bias Variance Labs</Link> 2021</span>
                    <br/><br/>
                </div> */}
                <Snackbar open={this.state.openCopyToClipboardSnackBar} autoHideDuration={2000} onClose={this.handleCloseSnackBar} className={classes.snackBar}>
                    <Alert onClose={this.handleCloseSnackBar} severity="success" className={classes.snackBar}>
                        Copied to Clipboard
                    </Alert>
                </Snackbar>
            </div>

        );
    }
}
export default withStyles(useStyles)(Dataset)