import './App.css';
import RouterC from './Components/RouterC';
import { BrowserRouter } from 'react-router-dom'

function App() {
  return (
    <BrowserRouter>
    <RouterC></RouterC>
    </BrowserRouter>
   
  );
}

export default App;
